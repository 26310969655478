import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "patching-events"
    }}>{`Patching events`}</h1>
    <p>{`A patching event represents a single execution of a `}<a parentName="p" {...{
        "href": "/scheduling-patching"
      }}>{`patching plan`}</a>{`. Instead of cron window (like in plans) it has an exact execution time window defined.`}</p>
    <h2 {...{
      "id": "events-list"
    }}>{`Events list`}</h2>
    <p><a parentName="p" {...{
        "href": "https://autopatcher.nordcloudapp.com/events"
      }}>{`Events list view`}</a>{` in UI allows to filter the events by:`}</p>
    <ul>
      <li parentName="ul">{`Time`}
        <ul parentName="li">
          <li parentName="ul">{`Upcoming events which are generated in advance based on schedule plan (the default value is 3 days)`}</li>
          <li parentName="ul">{`Past events`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Patching status:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`upcoming`}</strong>{` - events which are generated in advance based on schedule plan`}</li>
          <li parentName="ul"><strong parentName="li">{`not approved`}</strong>{` - events which need to be manually approved before they can be executed`}</li>
          <li parentName="ul"><strong parentName="li">{`in progress`}</strong>{` - currently executing events`}</li>
          <li parentName="ul"><strong parentName="li">{`success`}</strong>{` - patching finished successfully`}</li>
          <li parentName="ul"><strong parentName="li">{`success manual`}</strong>{` - events which have at least one machine marked as `}<strong parentName="li">{`success manual`}</strong>{` and rest are `}<strong parentName="li">{`success`}</strong></li>
          <li parentName="ul"><strong parentName="li">{`failed`}</strong>{` - at least one of the following conditions is true:`}
            <ul parentName="li">
              <li parentName="ul">{`at least one machine has failed patching`}</li>
              <li parentName="ul">{`at least one `}<a parentName="li" {...{
                  "href": "/hooks"
                }}>{`plan-global pre/post hook`}</a>{` has failed`}</li>
              <li parentName="ul">{`at least one `}<a parentName="li" {...{
                  "href": "/hooks"
                }}>{`pre/post host hook`}</a>{` failed for at least one machine`}</li>
              <li parentName="ul">{`scanning for machines in a `}<a parentName="li" {...{
                  "href": "/scheduling-patching#step-32-dynamic-plan"
                }}>{`dynamic plan`}</a>{` has failed`}</li>
            </ul>
          </li>
          <li parentName="ul"><strong parentName="li">{`partial success`}</strong>{` - at least one machine succeeded but some failure occurred in other parts of the flow (see above)`}</li>
          <li parentName="ul"><strong parentName="li">{`deleted`}</strong>{` - event was deleted`}</li>
        </ul>
      </li>
    </ul>
    <p><img alt="event-list" src={require("../assets/events/event-list.png")} /></p>
    <h2 {...{
      "id": "event-details"
    }}>{`Event details`}</h2>
    <p>{`Event details view allows to:`}</p>
    <ul>
      <li parentName="ul">{`get PDF report - which is generated after patching`}</li>
      <li parentName="ul">{`view a list of machines from the given event along with their `}<strong parentName="li">{`patching status`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`success`}</strong>{` - machine was patches successfully`}</li>
          <li parentName="ul"><strong parentName="li">{`success no updates`}</strong>{` - no updates were available at the time of the patching`}</li>
          <li parentName="ul"><strong parentName="li">{`error`}</strong>{` - something wrong with AutoPatcher, contact support team`}</li>
          <li parentName="ul"><strong parentName="li">{`not available`}</strong>{` - machine was offline or SSM agent does not work`}</li>
          <li parentName="ul"><strong parentName="li">{`failed`}</strong>{` - machine patching has failed`}</li>
          <li parentName="ul"><strong parentName="li">{`omitted`}</strong>{` - at least one of the following conditions is true:`}
            <ul parentName="li">
              <li parentName="ul">{`at least one `}<a parentName="li" {...{
                  "href": "/hooks#plan-global-hooks"
                }}>{`plan-global pre hook`}</a>{` has failed (in this case all machines are marked as `}<strong parentName="li">{`omitted`}</strong>{`)`}</li>
              <li parentName="ul">{`at least one `}<a parentName="li" {...{
                  "href": "/hooks#host-hooks-machine-specific"
                }}>{`pre host hook`}</a>{` for this machine has failed`}</li>
              <li parentName="ul">{`the patching command generation has failed (e.g. if the `}<strong parentName="li">{`s3_custom_script`}</strong>{` field in the machine is defined but AutoPatcher has failed to retrieve the script from S3)`}</li>
            </ul>
          </li>
          <li parentName="ul"><strong parentName="li">{`timeout`}</strong>{` - when instance patching took more time that time window length - it will result with the `}<inlineCode parentName="li">{`TimedOut`}</inlineCode>{`. In this case we advice to check patching manually and increase time window if it's possible.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`change patching status manually - `}<a parentName="li" {...{
          "href": "#manual-status-change"
        }}>{`read more`}</a></li>
      <li parentName="ul">{`show update log - output from machine`}</li>
      <li parentName="ul">{`show report from single machine - what patching update were made`}</li>
    </ul>
    <p><img alt="event-details" src={require("../assets/events/event-details.png")} /></p>
    <h2 {...{
      "id": "event-execution-timeline"
    }}>{`Event execution timeline`}</h2>
    <p>{`Since the version `}<inlineCode parentName="p">{`1.131`}</inlineCode>{` AutoPatcher handles long-running patchings in a different manner.
The change was introduced mainly in order to prevent the event execution to span out
of its reserved time window thus potentially delaying the execution of the following
events in a pipeline.`}</p>
    <p>{`The additional effect of this change is that it prevents running several patchings
on the same machine simultaneously. More precisely, AutoPatcher will never run more than one
SSM command on the same machine at the same time.`}</p>
    <p>{`The detailed description of the new behavior is presented in the following subsection.`}</p>
    <h3 {...{
      "id": "new-timeout-behavior"
    }}>{`New timeout behavior`}</h3>
    <p>{`An example patching flow is presented below. It represents two machines (`}<inlineCode parentName="p">{`M1`}</inlineCode>{` and `}<inlineCode parentName="p">{`M2`}</inlineCode>{`)
being patched by three different patching events (`}<inlineCode parentName="p">{`Event A`}</inlineCode>{`, `}<inlineCode parentName="p">{`Event B`}</inlineCode>{` and `}<inlineCode parentName="p">{`Event C`}</inlineCode>{`).`}</p>
    <p><img alt="timeline" src={require("../assets/events/timeline.jpg")} /></p>
    <p>{`The important points of what is happening in the timeline are described below:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`6:00`}</inlineCode>{` - `}<inlineCode parentName="li">{`Event A`}</inlineCode>{` starts executing by running two simultaneous patching commands via SSM:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`C1`}</inlineCode>{` for `}<inlineCode parentName="li">{`M1`}</inlineCode></li>
          <li parentName="ul">{`and `}<inlineCode parentName="li">{`C3`}</inlineCode>{` for `}<inlineCode parentName="li">{`M2`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`6:45`}</inlineCode>{` - The event's time window ends, however two SSM commands are still in progress.
Since the patching command did not finish in the reserved time window, both machines
are marked as `}<inlineCode parentName="li">{`TimedOut`}</inlineCode>{` in `}<inlineCode parentName="li">{`Event A`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`6:55`}</inlineCode>{` - The `}<inlineCode parentName="li">{`C3`}</inlineCode>{` SSM command is finished and AutoPatcher registers that fact in the background.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`7:00`}</inlineCode>{` - Event `}<inlineCode parentName="li">{`B`}</inlineCode>{` starts executing.
The machine `}<inlineCode parentName="li">{`M2`}</inlineCode>{` starts patching immediately since its previous patching command `}<inlineCode parentName="li">{`C3`}</inlineCode>{` has already finished.
The machine `}<inlineCode parentName="li">{`M1`}</inlineCode>{` however still has an SSM command (`}<inlineCode parentName="li">{`C1`}</inlineCode>{`) running in the background
therefore AutoPatcher waits for this command to finish before starting `}<inlineCode parentName="li">{`M1`}</inlineCode>{` patching.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`7:15`}</inlineCode>{` - The `}<inlineCode parentName="li">{`C1`}</inlineCode>{` SSM command reaches a finite state in the background, which allows
AutoPatcher to start patching `}<inlineCode parentName="li">{`M1`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`7:35`}</inlineCode>{` - The patching of `}<inlineCode parentName="li">{`M2`}</inlineCode>{` finishes (the exact status depends on the SSM command status
and is not relevant here) and the execution of a post-host hook for `}<inlineCode parentName="li">{`M2`}</inlineCode>{` starts.
Since the type of the hook is `}<inlineCode parentName="li">{`script`}</inlineCode>{` it is executed (similar to the patching) as an SSM command.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`7:45`}</inlineCode>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`The time window of `}<inlineCode parentName="li">{`Event B`}</inlineCode>{` ends. The patching for `}<inlineCode parentName="li">{`M1`}</inlineCode>{` (command `}<inlineCode parentName="li">{`C2`}</inlineCode>{`) is still in progress
in SSM so `}<inlineCode parentName="li">{`M1`}</inlineCode>{` is marked as `}<inlineCode parentName="li">{`TimedOut`}</inlineCode>{` in `}<inlineCode parentName="li">{`Event B`}</inlineCode>{`. The command executing the post-host hook script
for `}<inlineCode parentName="li">{`M2`}</inlineCode>{` is in progress as well, so the hook status is given and error code `}<inlineCode parentName="li">{`500`}</inlineCode>{` and the appropriate message
about the timeout is associated with the hook.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Event C`}</inlineCode>{` starts execution at the same time. Since both machines still have running SSM commands in the background
no action is performed on the machines by AutoPatcher yet.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`7:55`}</inlineCode>{` - The background command `}<inlineCode parentName="li">{`C5`}</inlineCode>{` reaches a finite state and AutoPatcher starts patching `}<inlineCode parentName="li">{`M2`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`8:15`}</inlineCode>{` - The time window of `}<inlineCode parentName="li">{`Event C`}</inlineCode>{` ends. The patching command for `}<inlineCode parentName="li">{`M2`}</inlineCode>{` finishes at the same time
so `}<inlineCode parentName="li">{`M2`}</inlineCode>{`'s status becomes the same as the command `}<inlineCode parentName="li">{`C6`}</inlineCode>{` status.
The `}<inlineCode parentName="li">{`C2`}</inlineCode>{` background command is still in progress however, and since AutoPatcher wasn't able to even start
the `}<inlineCode parentName="li">{`M1`}</inlineCode>{` patching in `}<inlineCode parentName="li">{`Event C`}</inlineCode>{`, this machine is marked as `}<inlineCode parentName="li">{`Not_in_time_window`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`8:20`}</inlineCode>{` - The `}<inlineCode parentName="li">{`C2`}</inlineCode>{` SSM command finally finishes in the background. The command status returned by SSM
is not recorded anywhere in `}<inlineCode parentName="li">{`Event B`}</inlineCode>{` nor `}<inlineCode parentName="li">{`Event C`}</inlineCode>{` however. `}<inlineCode parentName="li">{`M1`}</inlineCode>{` retains its status `}<inlineCode parentName="li">{`TimedOut`}</inlineCode>{`
and `}<inlineCode parentName="li">{`Not_in_time_window`}</inlineCode>{` in those events respectively. On the other hand the `}<inlineCode parentName="li">{`M1`}</inlineCode>{` becomes "unblocked"
for the potential next patchings.`}</li>
    </ul>
    <h2 {...{
      "id": "manual-events-approving"
    }}>{`Manual events approving`}</h2>
    <p>{`When a single event from a pipeline step is approved manually, it is given the temporary `}<strong parentName="p">{`APPROVED`}</strong>{` status along with all other events in the same step. When all the previous steps are finished (e.g. if all events from them have statuses `}<strong parentName="p">{`DONE`}</strong>{`, `}<strong parentName="p">{`DONE_MANUAL`}</strong>{`, `}<strong parentName="p">{`DONE_PARTIAL`}</strong>{`, `}<strong parentName="p">{`DONE_ERROR`}</strong>{`, `}<strong parentName="p">{`DONE_ERROR_MANUAL`}</strong>{` or `}<strong parentName="p">{`DELETED`}</strong>{`), all `}<strong parentName="p">{`APPROVED`}</strong>{` events from the current step are automatically transitioned to `}<strong parentName="p">{`NEW`}</strong>{` (aka `}<strong parentName="p">{`UPCOMING`}</strong>{` - the UI label for `}<strong parentName="p">{`NEW`}</strong>{`) state, which means they will be executed on their schedule (`}<strong parentName="p">{`APPROVED`}</strong>{` events won't be executed).`}</p>
    <p>{`Example:`}</p>
    <p>{`A → B → C`}</p>
    <p>{`If step A is finished but B is still in progress (or `}<strong parentName="p">{`NEW`}</strong>{`, or `}<strong parentName="p">{`NOT_APPROVED`}</strong>{`), approving C will not give the desired results, as it will be stuck in the `}<strong parentName="p">{`APPROVED`}</strong>{` state and won't start. The user should either wait for all events from B to finish or to delete them manually before approving of the C step.`}</p>
    <h2 {...{
      "id": "advanced-features"
    }}>{`Advanced features`}</h2>
    <h3 {...{
      "id": "partial-patching"
    }}>{`Partial patching`}</h3>
    <p>{`This feature allows the user to run patching process for the selected subset of machines, `}<a parentName="p" {...{
        "href": "/hooks"
      }}>{`event-global hooks and host hooks`}</a>{` in an already finished event.`}</p>
    <h4 {...{
      "id": "step-1"
    }}>{`Step 1`}</h4>
    <p>{`Select event with failed status and click the `}<strong parentName="p">{`RUN PARTIAL PATCHING`}</strong>{` button next to the status`}</p>
    <p><img alt="partial-step-1" src={require("../assets/events/partial/step-1.png")} /></p>
    <h4 {...{
      "id": "step-2"
    }}>{`Step 2`}</h4>
    <p>{`Manually select which steps you want to re-run: pre hooks, post hooks or machine-specific:`}</p>
    <p><img alt="partial-step-2" src={require("../assets/events/partial/step-2.png")} /></p>
    <p>{`By default all machines, pre hooks and post hooks are included, you can manually remove some of them from the list.`}</p>
    <h4 {...{
      "id": "step-3"
    }}>{`Step 3`}</h4>
    <p>{`By clicking the `}<strong parentName="p">{`edit`}</strong>{` icon to the left of a machine you can define machine-specific partial patching options:`}</p>
    <p><img alt="partial-step-3-1" src={require("../assets/events/partial/step-3_1.png")} /></p>
    <p><img alt="partial-step-3-2" src={require("../assets/events/partial/step-3_2.png")} /></p>
    <p>{`By default all pre and post host hooks are included, you can manually remove some of them from the list.`}</p>
    <h4 {...{
      "id": "report-from-partial-patching"
    }}>{`Report from partial patching`}</h4>
    <p>{`When a machine is patched multiple times in the context of the same event (by calling partial patching on it at least once)
the patching report is built based on the output from the `}<strong parentName="p">{`last partial patching only`}</strong>{`.`}</p>
    <h4 {...{
      "id": "technical-details"
    }}>{`Technical details`}</h4>
    <h5 {...{
      "id": "patching-status"
    }}>{`Patching status`}</h5>
    <p>{`Only finished events can be partially executed. If a request for partial patching is issued for an event with a different status an error will be returned. Otherwise, the event's status will be set to IN_PROGRESS until partial patching is finished.`}</p>
    <h5 {...{
      "id": "start-and-end-times"
    }}>{`Start and end times`}</h5>
    <p><strong parentName="p">{`Start time`}</strong>{` and `}<strong parentName="p">{`end time`}</strong>{` values for the event are kept unchanged after partial patching.`}</p>
    <h5 {...{
      "id": "pipelines"
    }}>{`Pipelines`}</h5>
    <p>{`There is a slight difference in `}<a parentName="p" {...{
        "href": "/scheduling-patching#scheduling-a-patching-plan-pipeline"
      }}>{`pipeline`}</a>{` logic with regard to partial patching. Let's consider the following scenario:`}</p>
    <ul>
      <li parentName="ul">{`There is a pipeline of two events: A → B`}</li>
      <li parentName="ul">{`Event B has policy set to success_or_approval`}</li>
      <li parentName="ul">{`Event A has failed, so event B has `}<strong parentName="li">{`not approved`}</strong>{` status`}</li>
      <li parentName="ul">{`A partial patching process is executed for the event A and in effect its status is changed to DONE (e.g. there was one failed machine in A which was patched successfully the second time)`}</li>
    </ul>
    <p>{`In this case the status of the event B will not be changed to `}<strong parentName="p">{`UPCOMING`}</strong>{` automatically and B will need to be approved manually.`}</p>
    <h3 {...{
      "id": "manual-status-change"
    }}>{`Manual status change`}</h3>
    <p>{`If some machine has not patched successfully during the automatic patching the user has the ability to change its status after some manual action was done on the machine. The typical use for this feature is to ensure that the patching report has an overall status `}<inlineCode parentName="p">{`SUCCCESS`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "available-input-options"
    }}>{`Available input options`}</h4>
    <p>{`The list of available manual statuses:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Success_manual`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Failed_manual`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`In_progress_manual`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Rebooting_manual`}</inlineCode></li>
    </ul>
    <p>{`If either `}<inlineCode parentName="p">{`Success_manual`}</inlineCode>{` or `}<inlineCode parentName="p">{`Failed_manual`}</inlineCode>{` is chosen an additional `}<inlineCode parentName="p">{`action_taken`}</inlineCode>{` field is required. It describes the action performed on the machine manually.`}</p>
    <p>{`If `}<inlineCode parentName="p">{`In_progress_manual`}</inlineCode>{` or `}<inlineCode parentName="p">{`Rebooting_manual`}</inlineCode>{` is chosen the status of the whole event is changed to `}<inlineCode parentName="p">{`IN_PROGRESS`}</inlineCode>{` until this machine is changed to `}<inlineCode parentName="p">{`Success_manual`}</inlineCode>{` or `}<inlineCode parentName="p">{`Failed_manual`}</inlineCode>{`.`}</p>
    <p>{`If there is a failed host hook for some machine and it is the only reason the event is in `}<inlineCode parentName="p">{`DONE_PARTIAL`}</inlineCode>{` state changing the machine's status to `}<inlineCode parentName="p">{`Success_manual`}</inlineCode>{` will transfer the event to `}<inlineCode parentName="p">{`DONE_MANUAL`}</inlineCode>{` state. In other words, the failed status of host hooks of machines with `}<inlineCode parentName="p">{`Success_manual`}</inlineCode>{` status is not taken into account while calculating the overall event status.`}</p>
    <h4 {...{
      "id": "changing-the-status-in-ui"
    }}>{`Changing the status in UI`}</h4>
    <p><img alt="manual-status-change" src={require("../assets/events/manual-status-change.png")} />{`
`}<img alt="manual-status-change-modal" src={require("../assets/events/manual-status-change-modal.png")} /></p>
    <h4 {...{
      "id": "change-the-status-in-cli"
    }}>{`Change the status in CLI`}</h4>
    <h5 {...{
      "id": "step-1-1"
    }}>{`Step 1`}</h5>
    <p>{`Prepare a file named `}<inlineCode parentName="p">{`change.json`}</inlineCode>{` with the following content, replacing `}<inlineCode parentName="p">{`<EVENT_ID>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<MACHINE_ID>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<STATUS>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<ACTION>`}</inlineCode>{` with the appropriate values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "event_id": "<EVENT_ID>",
    "machine_id": "<MACHINE_ID>",
    "manual_status": "<STATUS>",
    "action_taken": "<ACTION>"
}
`}</code></pre>
    <h5 {...{
      "id": "step-2-1"
    }}>{`Step 2`}</h5>
    <p>{`Run the following CLI command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nc-autopatcher-cli event change_status --file change.json
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      